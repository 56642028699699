import { navigate } from 'gatsby';
import * as React from 'react';

export default function Index() {

  React.useEffect( () => {
    navigate( '/my-customers' );
  }, [] );
  return (
    <>
    </>
  );
}
